import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { userTutorialCompletion } from "../../actions";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const TourModal = ({
  step,
  backProps,
  primaryProps,
  index,
  isLastStep,
  size,
  skipProps,
}) => {
  const dispatch = useDispatch();

  const hanldeClose = (e, buttonProps) => {
    buttonProps.onClick(e);
    dispatch(userTutorialCompletion(true));
  };

  const { t } = useTranslation();
  const nextButtonText = isLastStep ? "End Tour" : "Next";
  return (
    <div id="tour-tooltip">
      <div className="d-flex justify-content-between">
        <h3 className="text-start">{`${t("Getting Started")} (${
          index + 1
        }/${size})`}</h3>
        <Button
          onClick={(e) => {
            hanldeClose(e, skipProps);
          }}
          variant="outline-secondary"
          className="close-button"
          size="sm"
          role="close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>

      <div id="content">{step.content}</div>
      <div
        id="button-group"
        role="group"
        className=" d-flex justify-content-end"
      >
        <Button
          {...backProps}
          className="mr-1 btn btn-custom-light"
          role="Previous"
          size="medium"
        >
          <Trans>Previous</Trans>
        </Button>

        <Button
          onClick={(e) => {
            if (isLastStep) {
              hanldeClose(e, primaryProps);
            }
            primaryProps.onClick(e);
          }}
          size="medium"
          role={nextButtonText}
        >
          <Trans>{nextButtonText}</Trans>
        </Button>
      </div>
    </div>
  );
};

export default TourModal;
