import { DEFAULT_OVERLAY_OPACITY } from "../../../lib/constants";
import removeLayer from "./removeLayer";

const updateOverlays = (
  map,
  products,
  products_active,
  overlays,
  overlays_active,
  overlays_opacities
) => {
  if (
    !map ||
    !products ||
    !products.length ||
    !products_active ||
    !overlays ||
    !overlays_active
  ) {
    return;
  }

  const flattenOverlays = (overlaysCopy) =>
    overlaysCopy.reduce((arr, overlay) => {
      const children = overlay.children?.length
        ? flattenOverlays(overlay.children)
        : [];
      return [...arr, ...children, overlay];
    }, []);
  const flattenedOverlays = flattenOverlays([...overlays]);

  flattenedOverlays.forEach((overlay) => {
    if (overlays_active.includes(overlay.name)) {
      if (!map.getLayer(overlay.name)) {
        map.addSource(overlay.name, {
          type: overlay.type,
          url: overlay.url,
        });
        map.addLayer({
          id: overlay.name,
          type: overlay.type,
          source: overlay.name,
        });
      }
      const opacity = Number.parseInt(
        overlays_opacities[overlay.name] ?? DEFAULT_OVERLAY_OPACITY
      );
      map.setPaintProperty(overlay.name, "raster-opacity", opacity / 100);
    } else {
      if (map.getLayer(overlay.name)) {
        removeLayer(map, overlay.name);
      }
      if (map.getSource(overlay.name)) {
        map.removeSource(overlay.name);
      }
    }
  });
};

export default updateOverlays;
