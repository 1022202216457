import addMapLayer from "./addMapLayer";
import removeLayer from "./removeLayer";

const updateLayers = (map, layersList, activeLayerName) => {
  layersList.forEach((layer) => {
    if (activeLayerName === layer.name) {
      if (!map.getLayer(layer.name)) {
        addMapLayer(map, layer.name, layer.admin_level);
      }
    } else {
      removeLayer(map, layer.name);
    }
  });
};

export default updateLayers;
