export const INDICATOR_COLOR_SCALE = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1,
  "#d73027",
  -0.75,
  "#f46d43",
  -0.5,
  "#fdae61",
  -0.25,
  "#fee090",
  0,
  "#ffffbf",
  0.25,
  "#e0f3f8",
  0.5,
  "#abd9e9",
  0.75,
  "#74add1",
  1,
  "#4575b4",
];
