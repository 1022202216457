import { PRIMARY_LAYER_LINE_COLOR } from "../../../lib/constants";

const addMapLayer = (map, name, adminLevel) => {
  // prettier-ignore
  const default_style = {
    id: name,
    type: "line",
    source: "features",
    "source-layer": name,
    paint: {
      "line-color": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        "#a5a",
        PRIMARY_LAYER_LINE_COLOR,
      ],
      "line-width": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        4,
        ["boolean", ["feature-state", "selected"], false],
        3,
        1.5,
      ],
      "line-offset": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        2,
        ["boolean", ["feature-state", "selected"], false],
        2,
        0,
      ],
    },
  };
  const fill_style = {
    id: name + "-fill",
    type: "fill",
    source: "features",
    "source-layer": name,
    layout: {},
    paint: {
      "fill-color": "#444",
      "fill-opacity": 0.2,
    },
  };
  // prettier-ignore
  const labelStyle = {
      id: name + "-labels",
      type: "symbol",
      source: "features",
      "source-layer": name + "-labels",
      layout: {
        // get the title name from the source's "title" property
        "text-field": ["get", `name_${adminLevel}`],
        "text-font": ["DIN Pro Bold", "Arial Unicode MS Bold"],
        // "text-ignore-placement": true,
        "text-allow-overlap": false,
        // "text-offset": [0, 0],
        // "text-anchor": "top",
        "text-size": 14
      },
      paint: {
        "text-color": [
          "case",
            ["boolean", ["feature-state", "hover"], false], "#000",
            ["boolean", ["feature-state", "selected"], false], "#000",
          "#fff",
        ],
        "text-halo-color": [
          "case",
            ["boolean", ["feature-state", "hover"], false], "#fff",
            ["boolean", ["feature-state", "selected"], false], "#fff",
          "#000",
        ],
        "text-halo-width": [
          "case",
            ["boolean", ["feature-state", "hover"], false], 2,
            ["boolean", ["feature-state", "selected"], false], 3,
          1,
        ]
      },
    };

  map.addLayer(default_style, "tunnel-simple");
  map.addLayer(fill_style, name);
  map.addLayer(labelStyle, "poi-label");
};

export default addMapLayer;
