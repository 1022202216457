import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner = ({ style }) => (
  <Spinner animation="border" role="status" style={{ ...style }}>
    <span className="visually-hidden" style={{ display: "none" }}>
      Loading...
    </span>
  </Spinner>
);

export default LoadingSpinner;
