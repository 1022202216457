import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useI18next } from "gatsby-plugin-react-i18next";

import GeoGuardLogo from "../../assets/images/geoguard-logo@2x.png";
import CrafdLogo from "../../assets/images/CRAFd-DPPA-logo.svg";
import HeaderMainLogo from "../HeaderMainLogo";
import DatePicker from "./DatePicker";
import ModeToggle from "./ModeToggle";
import LayerSelector from "./LayerSelector";
import IndicatorsAccordionGroup from "./IndicatorsAccordionGroup";
import OverlaysAccordion from "./OverlaysAccordion";
import getCommonMaxDateFromProducts from "../../lib/getCommonMaxDateFromProducts";
import { dateSet } from "../../actions";

const Sidebar = (props) => {
  const { products } = useSelector((state) => state);
  const dispath = useDispatch();
  const { language } = useI18next();

  useEffect(() => {
    dispath(dateSet(getCommonMaxDateFromProducts(products)));
  }, [dispath, products]);

  return (
    <aside className="sidebar">
      <div className="sidebar-container">
        <HeaderMainLogo logo={<img src={GeoGuardLogo} alt="GeoGuard Logo" />} />
        <div className="sidebar-upper-container">
          <LayerSelector {...props} />
          <div
            className={`sidebar-upper-controls ${
              language === "ar" ? "custom-rtl-dir" : ""
            }`}
          >
            <DatePicker />
            <ModeToggle />
          </div>
        </div>
        <div className="sidebar-indicator-container">
          <IndicatorsAccordionGroup />
          <OverlaysAccordion />
        </div>
        <div className="sidebar-footer">
          <img
            src={CrafdLogo}
            alt="Crafd and DPPA Logo"
            style={{ height: 30 }}
          />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
