import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
export const steps = [
  {
    target: ".toggle-content",
    content: (
      <p className="font-weight-bold">
        <Trans>
          Choose a region, country, or other administrative boundary to show on
          the map.
        </Trans>
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: ".card-body> .list-group> .list-group-item:first-child",
    content: (
      <React.Fragment>
        <p className="font-weight-bold">
          <Trans>
            Click on an indicator or overlay to visualize changes on the map.
          </Trans>{" "}
        </p>
        <p>
          <Trans>
            Grey regions on the map indicate that no data is available.
          </Trans>
        </p>
        <p>
          <Trans>
            Enable multiple indicators in order to compare and identify
            connections.
          </Trans>
        </p>
      </React.Fragment>
    ),
    placement: "right",
    disableBeacon: true,
  },
  {
    target: ".step-three",
    content: (
      <React.Fragment>
        <p className="font-weight-bold">
          <Trans>
            Once you select a region on the map, you can view the available data
            on an interactive graph.
          </Trans>
        </p>
        <p>
          <Trans>
            On the graph: For any missing values, a line will connect the last
            known data point to the next available data point on the graph.
            Missing data will not appear as a “zero” value and will not be
            factored into composite scores
          </Trans>
        </p>
      </React.Fragment>
    ),
    placement: "top",
    disableBeacon: true,
  },
  {
    target: ".date-range-selection",
    content: (
      <React.Fragment>
        <p className="font-weight-bold">
          <Trans>
            Use the calendar to show data for a specific month on the map.
          </Trans>
        </p>
        <p>
          <Trans>
            Data is averaged for each month to provide a common denominator for
            comparison.
          </Trans>{" "}
          <a
            href="/help-about"
            className="link-opacity-100-hover d-inline-block"
            target="_blank"
          >
            <Trans>Learn more</Trans>
          </a>
        </p>
      </React.Fragment>
    ),
    placement: "right",
    disableBeacon: true,
  },
  {
    target: ".btn-group > .sidebar-indicator-button-selected",
    content: (
      <React.Fragment>
        <p className="font-weight-bold">
          <Trans>
            Use this toggle to compare multiple indicators or multiple regions.
          </Trans>
        </p>
      </React.Fragment>
    ),
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: ".map-nav-bar > .map-nav-ele-right",
    content: (
      <React.Fragment>
        <p className="font-weight-bold">
          <Trans>
            View the Documentation and Help page to explore methodologies, user
            guidance, and FAQs.
          </Trans>
        </p>
      </React.Fragment>
    ),
    placement: "bottom",
    disableBeacon: true,
  },
];
