import React from "react";
import { Link } from "gatsby";
import LanguageSelector from "../LanguageSelector";
import { Trans } from "gatsby-plugin-react-i18next";

const Navbar = () => (
  <div className="map-nav-bar">
    <div className="map-nav-ele map-nav-ele-left">
      <LanguageSelector className="map-nav-dropdown-link" showIcon={false} />
    </div>
    <div className="map-nav-ele map-nav-ele-mid">
      <Link to={"/account"}>
        <Trans>Your account</Trans>
      </Link>
    </div>
    <div className="map-nav-ele map-nav-ele-right">
      <Link to={"/help-about"}>
        <Trans i18nKey="helpTitle">Documentation & Help</Trans>
      </Link>
    </div>
  </div>
);

export default Navbar;
