import { COMPOSITE } from "../../../lib/constants";
import { INDICATOR_COLOR_SCALE } from "../mapboxLayerColorScale";

const OPACITY_SCALE = 0.8;

const addRiskStyle = (
  map,
  visibleProduct,
  date,
  activeLayerName,
  activeFeatures,
  timeseries,
  timeseriesFeatures
) => {
  const riskByFeatureId = new Map();
  timeseries.forEach((row) => {
    if (
      row["product_id"] === visibleProduct ||
      // Multi-indicator composites
      (visibleProduct === COMPOSITE &&
        row["product_id"].includes(visibleProduct))
    ) {
      riskByFeatureId.set(row["feature_id"], {
        ratio: row["ratio"],
        value: row["value"],
        ymt: row["ymt"],
        ht: row["ht"],
      });
    }
  });

  // Multi-region: set composite risk according to selected date
  // Multi-indicator composites handled above
  if (timeseriesFeatures?.length) {
    const date_as_date = typeof date === "number" ? new Date(date) : date;
    const mm_dd_yyyy = date_as_date.toISOString().split("T")[0];
    const composite_risk = timeseriesFeatures
      .filter((row) => row.type === "multi-feature")
      .find((row) => row.date.startsWith(mm_dd_yyyy));
    riskByFeatureId.set(COMPOSITE, { ratio: composite_risk?.ratio });
  }

  const relatedFeatures = map.querySourceFeatures("features", {
    sourceLayer: activeLayerName,
  });

  const layerFeatures = activeFeatures[activeLayerName] ?? [];

  relatedFeatures.forEach((feature) => {
    let risk =
      riskByFeatureId.get(feature.id) ?? riskByFeatureId.get(visibleProduct);
    if (layerFeatures.length > 1 && layerFeatures.includes(feature.id)) {
      risk = riskByFeatureId.get(COMPOSITE);
    }
    if (risk) {
      map.setFeatureState(
        { source: "features", sourceLayer: activeLayerName, id: feature.id },
        {
          indicator: risk["ratio"],
          value: risk["value"],
          ymt: risk["ymt"],
          ht: risk["ht"],
        }
      );
    }
  });

  map.setPaintProperty(
    activeLayerName + "-fill",
    "fill-color",
    [
      "case",
      ["!=", ["feature-state", "indicator"], null],
      INDICATOR_COLOR_SCALE,
      "#fff",
    ],
    false
  );

  // prettier-ignore
  map.setPaintProperty(
    activeLayerName + "-fill",
    "fill-opacity",
    [
      "case",
      ["!=", ["feature-state", "indicator"], null],
      OPACITY_SCALE,
      0.1,
    ],
    false
  );
};

export default addRiskStyle;
