import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import {
  Card,
  ListGroup,
  Button,
  Accordion,
  AccordionContext,
} from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

const CustomCategoryToggle = ({ eventKey, callback }) => {
  const activeEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isOpen = activeEventKey === eventKey;

  return (
    <Button type="button" onClick={decoratedOnClick}>
      <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
    </Button>
  );
};

const CategoryHeader = ({ eventKey, iconSrc, label, selectionsCount }) => (
  <Card.Header>
    <div className="category-header-text">
      {iconSrc ? (
        <img
          src={iconSrc}
          alt="Indicator category icon"
          style={{ height: 20 }}
        />
      ) : (
        <FontAwesomeIcon icon={faDatabase} />
      )}
      {label}
    </div>
    <div className="category-header-control">
      {selectionsCount > 0 && (
        <span className="selections-count">{selectionsCount}</span>
      )}
      {AccordionContext && <CustomCategoryToggle eventKey={eventKey} />}
    </div>
  </Card.Header>
);

const BaseProductCategoryAccordion = ({
  categoryIcon,
  categoryLabel,
  selectionsCount,
  eventKey,
  children,
}) => (
  <Card>
    <CategoryHeader
      eventKey={eventKey}
      iconSrc={categoryIcon}
      label={categoryLabel}
      selectionsCount={selectionsCount}
    />
    <Accordion.Collapse eventKey={eventKey}>
      <Card.Body>
        <ListGroup>{children}</ListGroup>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
);

export default BaseProductCategoryAccordion;
