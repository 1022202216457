const config = {
  mapboxAccessToken:
    process.env.MAPBOX_ACCESS_TOKEN ||
    "pk.eyJ1IjoiZWxlbWVudDg0IiwiYSI6ImNsYjE2a2tpbDBqN2Qzb3BmaHlpdW9zY2gifQ.SyZBF-HN-WPtze9KQ5oNIQ",
  mapboxStyleId:
    process.env.MAPBOX_STYLE_ID ||
    "mapbox://styles/element84/clb16r1bc000215lg3i3b1t3u",
  api: process.env.GATSBY_API_URL,
};

export default config;
