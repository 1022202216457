import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import { toggleSelectionMode } from "actions";
import { SelectionMode } from "../../lib/constants";

const ModeToggle = () => {
  const { selectionMode } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useI18next();

  const onClickSetMode = () => {
    if (selectionMode === SelectionMode.MultiIndicators) {
      dispatch(toggleSelectionMode(SelectionMode.MultiRegions));
    }
    if (selectionMode === SelectionMode.MultiRegions) {
      dispatch(toggleSelectionMode(SelectionMode.MultiIndicators));
    }
  };

  return (
    <div className="sidebar-mode-toggle">
      <div className="sidebar-upper-label">{t("Compare multiple")}</div>
      <div>
        <ButtonGroup className={language === "ar" ? "custom-rtl-dir" : ""}>
          <Button
            variant="light"
            className={
              selectionMode === SelectionMode.MultiIndicators
                ? "sidebar-indicator-button-selected"
                : "sidebar-indicator-button"
            }
            onClick={() => onClickSetMode()}
          >
            {t("Indicators")}
          </Button>
          <Button
            variant="light"
            className={
              selectionMode === SelectionMode.MultiRegions
                ? "sidebar-indicator-button-selected"
                : "sidebar-indicator-button"
            }
            onClick={() => onClickSetMode()}
          >
            {t("Regions")}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ModeToggle;
