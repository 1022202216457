import React from "react";

import { Popover } from "react-bootstrap";

export const ProductInfoPopUp = ({ label, short_name, description }) => (
  <Popover
    id="popover-trigger-hover-focus"
    className="indicator-tooltip"
    title={label ?? short_name}
  >
    <div className="popup-title">{label ?? short_name}</div>
    <div className="popup-content">
      <span dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </Popover>
);

export default ProductInfoPopUp;
