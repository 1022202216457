import React, { useMemo } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { INDICATOR_COLOR_SCALE } from "./mapboxLayerColorScale";
import ConflictLegendCircleLarge from "../../assets/images/conflict-legend-circle-large.svg";
import ConflictLegendCircleMed from "../../assets/images/conflict-legend-circle-med.svg";
import ConflictLegendCircleSmall from "../../assets/images/conflict-legend-circle-small.svg";
import ConflictLegendCircleXS from "../../assets/images/conflict-legend-circle-xs.svg";

const Legend = (props) => {
  const { t } = useTranslation();
  const { products, products_active, visibleNonConflictProduct } = props;
  const slicedValues = INDICATOR_COLOR_SCALE.slice(3);
  const colors = slicedValues.filter((element, index) => index % 2 !== 0);
  const values = slicedValues.filter((element, index) => index % 2 === 0);

  const legendTitle = useMemo(() => {
    if (visibleNonConflictProduct === "composite") {
      return t("Composite");
    }
    const product = products.find(
      ({ sidebar, name }) => sidebar && name === visibleNonConflictProduct
    );
    if (product) {
      return product.label ?? product.short_name;
    }
    return "";
  }, [visibleNonConflictProduct, products]);

  const activeConflictProduct = products.find(
    ({ category, sidebar, name }) =>
      category === "Conflict" && sidebar && products_active.includes(name)
  );

  return !!products_active.length ? (
    <aside className="legend">
      {!!visibleNonConflictProduct && (
        <div className="nonconflicts-legend">
          <div className="legend-header">
            <h5 className="title">{legendTitle}</h5>
            <p className="sub-title">{t("Variance from historical average")}</p>
          </div>
          <ul>
            {colors.map((color, idx) => (
              <li key={idx}>
                <span
                  className="legend-color"
                  style={{ backgroundColor: color }}
                ></span>
                <label>{`${values[idx] * 100}%`}</label>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!activeConflictProduct && (
        <div className="conflict-legend">
          <div className="conflict-title">
            <h5>{t("Conflict")}</h5>
          </div>
          <div className="conflict-legends-wrapper">
            <div className="conflict-legends">
              <img
                src={ConflictLegendCircleXS}
                alt="Conflict map legend extra small"
              />
              <img
                src={ConflictLegendCircleSmall}
                alt="Conflict map legend small"
              />
              <img
                src={ConflictLegendCircleMed}
                alt="Conflict map legend medium"
              />
              <img
                src={ConflictLegendCircleLarge}
                alt="Conflict map legend big"
              />
            </div>
            <div className="conflict-legends-description">
              <span>{t("Fewer")}</span>
              <span>{t("More")}</span>
            </div>
          </div>
        </div>
      )}
    </aside>
  ) : null;
};

export default Legend;
