import React from "react";
// Custom Dropdown toggle component from the react-bootstrap docs:
// The forwardRef is important!
// Dropdown needs access to the DOM node in order to position the Menu
const LayerDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));
LayerDropdownToggle.displayName = "LayerDropdownToggle";

export default LayerDropdownToggle;
