const removeLayer = (map, name) => {
  if (map.getLayer(name)) {
    map.removeLayer(name);
  }
  if (map.getLayer(name + "-fill")) {
    map.removeLayer(name + "-fill");
  }
  if (map.getLayer(name + "-labels")) {
    map.removeLayer(name + "-labels");
  }
};

export default removeLayer;
